import React, { useEffect, useState, useRef, useMemo } from "react"
import { Link, graphql } from "gatsby"
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers"
import Container from "../components/Container/Container"
import GraphQLErrorList from "../components/graphql-error-list"
import Seo from "../components/SEO/SEO"
import Image from "gatsby-plugin-sanity-image"
import InView from "../components/InView"
import { useMediaQuery } from "react-responsive"
import Enquiry from "../components/Global/Enquiry"
import ProjectPreview from "../components/Projects/ProjectPreview"
import useParallaxBlock from "../lib/parallaxBlock"

export const query = graphql`
  query ProjectPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      defaultImage {
        asset {
          _id
        }
      }
      enquiryCTAImage {
        ...Image
      }
      enquiryCTATitle
      enquiryCTAText
    }
    sanityOurProjects(_id: { regex: "/^(?!drafts.).*$/" }) {
      title
      description
      _id
      hero {
        kind
        heroImage {
          ...Image
          alt
        }
        heroVideoUrl
      }
    }
    projects: allSanityProject(
      sort: { fields: [projectType___sort_order, order], order: ASC }
      filter: { published: { ne: false } }
    ) {
      edges {
        node {
          id
          previewImage {
            ...Image
            alt
          }
          title
          projectStatus
          slug {
            current
          }
          projectType {
            id
            title
          }
        }
      }
    }
    categories: allSanityProjectType(
      sort: { fields: [sort_order], order: ASC }
    ) {
      edges {
        node {
          title
          sort_order
          id
        }
      }
    }
  }
`

const OurProjects = (props) => {
  const { data, errors } = props
  const site = (data || {}).site
  const [projects, setProjects] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [hideProjects, setHideProjects] = useState(false)
  const [selectedFilter, setSelectedFilter] = useState(null)
  const topRef = useRef()

  const projectNodes = useMemo(() => {
    return (data || {}).projects
      ? mapEdgesToNodes(data.projects).filter(filterOutDocsWithoutSlugs)
      : []
  }, [data])

  // Projects
  useEffect(() => {
    setProjects(projectNodes)
  }, [projectNodes])

  // Manually sort the categories
  var categoryNodes =
    data && data.categories && mapEdgesToNodes(data.categories)

  function filterProjects(filter) {
    console.log("Filter ID:", filter)
    setSelectedFilter(filter)
    setHideProjects(true)
    setTimeout(() => {
      if (filter === null || filter === "all") {
        setProjects(projectNodes)
      } else {
        setProjects(
          projectNodes.filter((el) =>
            el.projectType.map((el) => el.id).includes(filter)
          )
        )
      }
      setHideProjects(false)
    }, 600)
  }

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 })
    return isDesktop ? children : null
  }
  const Default = ({ children }) => {
    const isNotMobile = useMediaQuery({ maxWidth: 1023 })
    return isNotMobile ? children : null
  }

  const parallaxA = useParallaxBlock((scale) => {
    return 0.6 + scale * 0.6
  })

  const parallaxB = useParallaxBlock((scale) => {
    return 1.2 - scale * 1.2
  })

  function ProjectListing(props) {
    return (
      <>
        <Default>
          <div className="col-1">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 0)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 2 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Default>
        <Desktop>
          <div className="col-1" ref={parallaxB}>
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 0)
                .map((node) => (
                  <div
                    className="overflow-hidden mb-6 md:mb-10 md:pl-10"
                    key={node.id}
                  >
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-2">
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 1)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
          <div className="col-3" ref={parallaxA}>
            {props.nodes &&
              props.nodes
                .filter((n, i) => i % 3 === 2)
                .map((node) => (
                  <div className="mb-6 md:mb-10 md:pl-10" key={node.id}>
                    <ProjectPreview {...node} />
                  </div>
                ))}
          </div>
        </Desktop>
      </>
    )
  }

  ProjectListing.defaultProps = {
    title: "",
    nodes: [],
  }

  function DesktopCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <li className="my-px" key={node.id}>
              <DesktopCategory {...node} />
            </li>
          ))}
      </>
    )
  }

  DesktopCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  }

  function scrollTo() {
    topRef.current.scrollIntoView({ behavior: "smooth" })
  }

  function DesktopCategory(props) {
    return (
      <button
        onClick={() => {
          filterProjects(props.id)
          scrollTo()
        }}
        className={
          selectedFilter === props.id
            ? "border-b border-b-black"
            : "border-b border-b-transparent text-grey-default transition-all ease-in-out duration-300 hover:text-black hover:border-b hover:border-black"
        }
      >
        {props.title}
      </button>
    )
  }

  function MobileCategoryListing(props) {
    return (
      <>
        {props.nodes &&
          props.nodes.map((node) => (
            <option key={node.id} value={node.id}>
              {node.title}
            </option>
          ))}
      </>
    )
  }

  MobileCategoryListing.defaultProps = {
    title: "",
    nodes: [],
  }

  if (errors) {
    return (
      <>
        <GraphQLErrorList errors={errors} />
      </>
    )
  }

  return (
    <>
      <Seo
        title={data.sanityOurProjects.title}
        description={data.sanityOurProjects.description}
        image={
          data.sanityOurProjects.hero.heroImage
            ? data.sanityOurProjects.hero.heroImage
            : site.defaultImage
        }
      />
      <Container>
        <div className="box-border border-b border-grey-default">
          <div className="grid grid-cols-1 mb-4 md:grid-cols-2 bg-off-white md:mb-0">
            <div className="px-6 py-10 md:pl-10 md:my-[120px] md:mr-[120px] flex flex-col order-2 md:order-1">
              <div className="md:my-auto text-[14px] leading-[24px] max-w-[400px]">
                <h2>Our Projects</h2>
                <h1 className="font-serif text-[40px] leading-[1.4] md:text-[56px] md:leading-[72px] my-6 md:my-10">
                  {data.sanityOurProjects.title}
                </h1>
                <div className="text-[#666]">
                  {data.sanityOurProjects.description}
                </div>
                <Link
                  className="link text-[#1B1B1B] mt-6 md:mt-10"
                  to="/contact-us/"
                >
                  Enquire now
                </Link>
              </div>
            </div>
            {data.sanityOurProjects.hero.kind === "image" && (
              <div className="order-1 md:order-2">
                <Image
                  {...data.sanityOurProjects.hero.heroImage}
                  width={880}
                  height={880}
                  alt={data.sanityOurProjects.hero.heroImage.alt}
                  className={
                    `aspect-[390/305] md:aspect-[720/610] object-cover object-center h-full lazy ` +
                    (loaded && " loaded")
                  }
                  onLoad={() => setLoaded(true)}
                />
              </div>
            )}
            {/* 
            {data.sanityOurProjects.hero.kind === "video" && (
              <div
                className={
                  `hero-wrapper video-wrapper lazy ` + (loaded && " loaded")
                }
              >
                <ReactPlayer
                  width="100vw"
                  height="100vh"
                  playing
                  playsinline={true}
                  loop
                  muted
                  url={data.sanityOurProjects.hero.heroVideoUrl}
                  onStart={() => setLoaded(true)}
                />
              </div>
            )} */}
          </div>

          <InView>
            <div className="relative pb-10 md:pb-0">
              <div className="container relative flex flex-wrap flex-start md:pt-[120px] md:min-h-screen">
                <div className="w-full pt-6 md:pt-0 md:w-1/4 md:sticky md:h-full md:top-[81px] md:pb-[25px] md:pr-10">
                  <h3 className="text-[16px] leading-[28px] mb-3 md:mb-6">
                    Filter by type
                  </h3>
                  <ul className="font-serif text-[24px] lg:text-[32px] leading-[1.2] hidden md:flex md:flex-col md:gap-4">
                    <li>
                      <button
                        onClick={() => {
                          filterProjects(null)
                          scrollTo()
                        }}
                        className={
                          selectedFilter === null
                            ? "border-b border-b-black"
                            : "border-b border-b-transparent text-grey-default transition-all ease-in-out duration-300 hover:text-black hover:border-b hover:border-black"
                        }
                      >
                        All
                      </button>
                    </li>
                    {categoryNodes && (
                      <DesktopCategoryListing nodes={categoryNodes} />
                    )}
                  </ul>
                  <select
                    className="md:hidden text-[14px] text-[#2e2e2e] text-opacity-50 w-full border p-3 border-grey-default box-border"
                    value={selectedFilter === null ? "all" : selectedFilter}
                    onChange={(event) => {
                      if (event.target.value === "all") {
                        filterProjects(null)
                      } else {
                        filterProjects(event.target.value)
                      }
                    }}
                  >
                    <option value="all">All</option>
                    {categoryNodes && (
                      <MobileCategoryListing nodes={categoryNodes} />
                    )}
                  </select>
                </div>
                <div className="mt-6 masonry--col-3 md:mt-0 w-full md:w-3/4 md:pb-[50%] relative md:border-l md:border-grey-default box-border">
                  <div
                    ref={topRef}
                    className="absolute top-[-100px] left-0"
                  ></div>
                  <div
                    className={`masonry grid gap-6 md:grid-cols-2 lg:grid-cols-3 md:gap-10 ${
                      hideProjects ? "hide" : ""}`}
                  >
                    <ProjectListing nodes={projects} />
                  </div>
                </div>
              </div>
            </div>
          </InView>
        </div>
        <Enquiry data={data} />
      </Container>
    </>
  )
}

export default OurProjects
